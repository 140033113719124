<template>
  <div :class="$style.recycleBinList">
    <div :class="$style.description">
      You can restore deleted processes from here. Processes in recycle bin are deleted forever
      after 30 days.
    </div>

    <div :class="$style.controls">
      <b-field v-if="processesLength">
        <b-input
          v-model="search"
          placeholder="Search"
          icon="magnifying-glass"
        />
      </b-field>
    </div>

    <div
      v-if="processesLength === 0"
      :class="$style.emptyMessageSection"
    >
      <div
        :class="$style.emptyMessageBlock"
      >
        <div>
          <font-awesome-icon
            :icon="['fad', 'trash-can-clock']"
            :class="$style.icon"
            size="2xl"
          />
        </div>
        <div>
          Your recycle bin is empty
        </div>
      </div>
    </div>

    <div
      v-if="filteredProcesses.length"
      :class="$style.list"
    >
      <b-table
        :is-row-selectable="_ => isMobile"
        :selected.sync="selectedProcessRow"
        :data="filteredProcesses"
        :class="$style.table"
        hoverable
      >
        <b-table-column
          v-slot="{
            row: {
              processName, id, inputType, outputType,
            },
          }"
          field="processName"
          sortable
          label="Name"
          width="50%"
        >
          <div :class="$style.process">
            <div
              :class="$style.info"
            >
              <ProcessType
                :input-type="inputType"
                :output-type="outputType"
              />

              <div :class="$style.name">
                {{ processName }}
              </div>
            </div>

            <div
              :class="[$style.panel, processWithOpenDropdown === processName && $style.interacts]">
              <div :class="$style.menu">
                <b-dropdown
                  :ref="processName"
                  position="is-bottom-left"
                  :animation="''"
                  @active-change="changeIsDropdownOpen($event, processName)"
                >
                  <template #trigger>
                    <div
                      :class="$style.trigger"
                      title="Menu"
                    >
                      <font-awesome-icon :icon="['fal', 'ellipsis-vertical']" />
                    </div>
                  </template>

                  <b-dropdown-item
                    :class="$style.restore"
                    @click="restore(id, processName)"
                  >
                    <font-awesome-icon :icon="['fal', 'clock-rotate-left']" />

                    <div :class="$style.text">
                      Restore
                    </div>
                  </b-dropdown-item>

                  <b-dropdown-item
                    :class="$style.deleteForever"
                    @click="deleteForever(id, processName)"
                  >
                    <font-awesome-icon :icon="['fal', 'trash-can']" />

                    <div :class="$style.text">
                      Delete forever
                    </div>
                  </b-dropdown-item>
                </b-dropdown>
              </div>
            </div>
          </div>
        </b-table-column>

        <b-table-column
          v-slot="{ row: { deletedAt } }"
          field="deletedAt"
          sortable
          label="Deleted date"
        >
          {{ formatDate({ date: deletedAt, format: 'MMM DD, hh:mm A' }) }}
        </b-table-column>

        <b-table-column
          v-slot="{ row: { deletedBy } }"
          sortable
          field="deletedBy"
          label="Deleted By"
        >
          {{ deletedBy }}
        </b-table-column>

        <b-table-column
          v-if="isMobile"
          v-slot="{ row: { id, processName } }"
          :td-attrs="(row, _) => ({
            class: !selectedProcessRow || row.id !== selectedProcessRow.id
              ? $style.hidden
              : $style.mobileProcessControlsCell,
          })"
        >
          <b-button
            type="is-primary"
            icon-left="clock-rotate-left"
            @click="restore(id, processName)"
          >
            Restore
          </b-button>
          <b-button
            type="is-danger"
            icon-left="trash-can"
            @click="deleteForever(id, processName)"
          >
            Delete
          </b-button>
        </b-table-column>
      </b-table>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'pinia';
import { useRecycleBinStore } from '@/stores/index.js';
import { formatDate } from '@/utils/index.js';
import Confirm from '@/components/modals/confirm.vue';
import ProcessType from '@/views/documents/common/components/process-type/index.vue';
import { openNotification } from '@/components/notification-service.js';

export default {
  name: 'RecycleBinList',
  components: {
    ProcessType,
  },
  data() {
    return {
      search: '',
      processWithOpenDropdown: null,
      selectedProcessRow: null,
      isMobile: false,
    };
  },
  computed: {
    ...mapState(useRecycleBinStore, ['deletedProcesses']),

    processesLength() {
      return this.deletedProcesses?.length;
    },

    filteredProcesses() {
      return this.search
        ? this.deletedProcesses.filter(({ processName }) => processName
          .toLowerCase()
          .includes(this.search.toLowerCase()))
        : this.deletedProcesses || [];
    },
  },

  mounted() {
    this.onWidthChange();
    window.addEventListener('resize', this.onWidthChange);
  },

  unmounted() {
    window.removeEventListener('resize', this.onWidthChange);
  },

  async created() {
    await this.readDeletedProcesses();
  },

  methods: {
    formatDate,
    ...mapActions(useRecycleBinStore, ['readDeletedProcesses', 'deleteProcessForever', 'restoreDeletedProcess']),

    changeIsDropdownOpen(isOpen, processName) {
      if (!isOpen && processName !== this.processWithOpenDropdown) return;
      this.processWithOpenDropdown = isOpen ? processName : null;
    },

    onWidthChange() {
      const newIsMobile = window.innerWidth <= 768;
      if (this.isMobile && !newIsMobile) {
        this.selectedProcessRow = null;
      }
      this.isMobile = newIsMobile;
    },

    async deleteForever(id, processName) {
      this.modal = this.$modal.show(
        Confirm,
        {
          title: `Delete process \"${processName}\" forever?`,
          onSubmit: async () => {
            await this.deleteProcessForever({ id, processName });
            await this.readDeletedProcesses();

            if (this.isMobile) {
              this.selectedProcessRow = null;
            }
          },
          submitBtnText: 'Delete forever',
          closeBtnText: 'Cancel',
          submitBtnType: 'is-danger',
        },
        {
          clickToClose: false, width: '440px', height: 'auto', adaptive: true, draggable: '.modal-handler',
        },
      );
    },

    async restore(id, processName) {
      this.modal = this.$modal.show(
        Confirm,
        {
          title: `Restore process \"${processName}\"?`,
          onSubmit: async () => {
            const { error } = await this.restoreDeletedProcess({
              id,
              processName,
            });

            if (error) {
              openNotification({
                message: 'Active process with this name already exists.',
                type: 'danger',
              });
              return;
            }

            await this.readDeletedProcesses();

            if (this.isMobile) {
              this.selectedProcessRow = null;
            }
          },
          closeBtnText: 'Cancel',
        },
        {
          clickToClose: false, width: '440px', height: 'auto', adaptive: true, draggable: '.modal-handler',
        },
      );
    },
  },
};
</script>

<style lang="scss" module>
.recycleBinList {

  .description {
    margin-bottom: 25px;
  }

  .hidden {
    display: none !important;
  }

  .controls {
    display: flex;
    align-items: end;
    justify-content: space-between;
    margin-bottom: 30px;
    flex-direction: column;
  }

  .emptyMessageSection{
    height: 192px;
    display: flex;
    justify-content: center;

    .emptyMessageBlock {
      display: inline-flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: row;
      width: 254px;
      padding: 80px 10px 80px 10px;

      .icon {
        --fa-primary-opacity: 0.3;
        --fa-secondary-opacity: 0.8;
        color: var(--current-color);
      }
    }
  }

  .table {
    td[data-label='Name'] {
      position: relative;
      padding: 0;
    }

    tr:hover {
      .panel {
        visibility: visible;
      }
    }
  }

  .process {
    position: absolute;
    display: flex;
    justify-content: space-between;
    inset: 0;

    .info {
      display: flex;
      align-items: center;
      gap: 15px;
      padding-left: 15px;
      width: calc(100% - 130px);

      .name {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }

    .panel {
      display: flex;
      padding-right: 15px;
      visibility: hidden;

      &.interacts {
        visibility: visible;
      }

      .menu,
      .trigger {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 30px;
        cursor: pointer;
      }

      .restore,
      .deleteForever {
        .text {
          display: inline;
          margin-left: 5px;
        }
      }

      .trigger {
        display: flex;
        align-items: center;
        height: 100%;

        svg {
          width: 15px;
          height: 25px;
        }
      }

      :global(.dropdown) {
        height: 100%;

        &:hover {
          background-color: var(--color-gray);
        }
      }

      :global(.dropdown.is-active) {
        background-color: var(--color-gray);
      }

      :global(.dropdown-trigger) {
        height: 100%;
      }

      :global(.dropdown-menu) {
        padding-top: 1px;
        min-width: initial;
      }

      :global(.dropdown-item) {
        padding-right: 30px;
        padding-left: 17px;
      }
    }
  }
}

@media (max-width: 768px) {
  .recycleBinList {

    .mobileProcessControlsCell {
      &::before {
        content: none !important;
      }
    }

    .hidden {
      display: none !important;
    }

    .mobileProcessControls {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

    .mobileControlsButtons {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

    .controls {
      flex-direction: column;
      align-items: stretch;

      &>*:not(:last-child) {
        margin-bottom: 30px;
      }
    }

    .table {
      td[data-label='Name'] {
        padding: 0.5em 0.75em;
        position: static;
      }

      :global(tr.is-selected) {
        background-color: white;
        color: hsl(0, 0%, 21%);
        border: solid 1px #7d4bcd;
      }
    }

    .process {
      position: absolute;
      top: 0;
      align-items: flex-start;
      padding: 8px 12px;
      padding-left: 66px;

      .panel {
        display: none;
      }

      .info {
        width: calc(100%);
        justify-content: flex-end;

        &::before {
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          content: '';
        }

        .name {
          order: -1;
        }
      }
    }

    :global(.table-mobile-sort) {
      display: none;
    }
  }
}
</style>
