export default {
  DOCX: {
    name: 'file-word',
    color: '#438fe0',
    label: 'DOCX',
  },
  XLSX: {
    name: 'file-excel',
    color: '#51b49f',
    label: 'XLSX',
  },
  PPTX: {
    name: 'file-powerpoint',
    color: '#dc5b26',
    label: 'PPTX',
  },
  PDF: {
    name: 'file-pdf',
    color: '#e34d43',
    label: 'Fillable PDF',
  },
  HTML: {
    name: 'file-code',
    color: '#a4a4a4',
    label: 'HTML',
  },
};
