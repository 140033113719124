<template>
  <div :class="$style.icon">
    <font-awesome-icon
      :icon="['fas', `${icon.name}`]"
      :style="{
        color: icon.color,
        backgroundColor: filled ? icon.color : null,
      }"
    />
  </div>
</template>

<script>
export default {
  name: 'ProcessIcon',
  props: {
    icon: {
      type: Object,
      default: () => ({}),
    },
    filled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" module>
.icon {
  display: flex;
  align-items: flex-start;
  font-size: 18px;
}
</style>
