<template>
  <div
    :class="$style.processType"
    :title="hint"
  >
    <Icon
      :icon="input"
      :filled="converted"
    />

    <Icon
      v-if="converted"
      :icon="output"
      :class="$style.converted"
    />
  </div>
</template>

<script>
import Icon from './icon.vue';
import processTypes from './process-types.js';

export default {
  name: 'ProcessType',
  components: {
    Icon,
  },
  props: {
    inputType: {
      type: String,
      default: '',
    },
    outputType: {
      type: String,
      default: '',
    },
  },
  computed: {
    converted() {
      return this.inputType !== this.outputType;
    },
    hint() {
      return `${this.inputType} document from ${this.outputType} template`;
    },
    input() {
      return processTypes[this.inputType];
    },
    output() {
      return processTypes[this.outputType];
    },
  },
};
</script>

<style lang="scss" module>
.processType {
  position: relative;

  .converted {
    position: absolute;
    right: -3px;
    top: -3px;
    box-shadow: -1px 1px 0 0 var(--color-white);
    background-color: var(--color-white);
  }
}
</style>
